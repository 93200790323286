<script lang="ts" setup>
import type { StakeAccount } from '~/store'
import { useWallet } from '@solana/wallet-adapter-vue'
import { useClipboard } from '@vueuse/core'
import { useStakeAccountStore } from '~/store'
import { formatAmount, lamportsToSol, shortenAddress } from '~/utils'

const wallet = useWallet()
const { connected } = wallet

const { copy } = useClipboard()
const Toast = useToast()

const showMore = ref(false)

const stakeAccountStore = useStakeAccountStore()
const loadingPubkeys = computed(() => stakeAccountStore.loadingPubkeys)

const validatorsStore = useValidatorsStore()
const getValidator = (voteId: string) => validatorsStore.allValidators.find(v => v.voteId === voteId)

const accountsFull = computed(() => {
  return stakeAccountStore.accountsFull
    .map((acc) => {
      return {
        ...acc,
        validator: getValidator(acc.stakeAccount.account.data?.parsed?.info?.stake?.delegation?.voter),
      }
    })
    .filter(acc => acc.state === 'active' && acc.validator && acc.validator.inJpool)
})

const showedAccounts = computed(() => showMore.value ? accountsFull.value : [...accountsFull.value].splice(0, 3))

const loading = computed(() => stakeAccountStore.loading)
async function depositJpool(stakeAccount: StakeAccount) {
  await stakeAccountStore.depositJpool([stakeAccount], stakeAccount.account.data?.parsed?.info?.stake?.delegation?.voter)
}

function amountNormalized(lamports = 0) {
  const num = Number(lamportsToSol(lamports))
  return num >= 0 ? formatAmount(num, num < 1 ? 5 : 3) : ''
}

function copyToClipboard(addr: string) {
  copy(addr)
  Toast.create({
    message: 'Copied to clipboard',
    variant: 'info',
  })
}
</script>

<template>
  <section class="redelegate">
    <h1 class="container-title text-32">
      {{ $t('smartStaking.redelegation.title') }}
    </h1>
    <div class="container-subtitle text-16">
      {{ $t('smartStaking.redelegation.subtitle') }}
    </div>

    <div v-if="loading">
      <j-skeleton width="100%" height="152" />
    </div>

    <div v-else-if="!loading && accountsFull.length > 0" class="redelegate-list">
      <div
        v-for="acc in showedAccounts"
        :key="acc.stakeAccount.pubkey.toBase58()"
        class="redelegate-list__item"
      >
        <j-avatar :image="acc?.validator?.iconUrl || acc?.validator?.image || ''" size="64px" spinner-color="#fff" />

        <div class="validator-info">
          <div class="validator-info__name">
            <j-tooltip>
              {{ acc.validator?.name }}
              <template #content>
                {{ acc.validator?.name }}
              </template>
            </j-tooltip>
          </div>
          <div class="validator-info__addr" @click="copyToClipboard(String(acc.validator?.voteId))">
            <j-tooltip>
              {{ shortenAddress(String(acc.validator?.voteId), 7) }}

              <template #content>
                {{ acc.validator?.voteId }}
              </template>
            </j-tooltip>
          </div>
          <div class="validator-info__amount">
            {{ amountNormalized(acc.stakeAccount?.account?.lamports) }} SOL
          </div>
        </div>

        <j-btn
          pill
          variant="primary"
          :loading="loadingPubkeys.has(acc.stakeAccount.pubkey.toBase58())"
          @click="depositJpool(acc.stakeAccount)"
        >
          {{ $t('smartStaking.redelegation.btn') }}
        </j-btn>
      </div>

      <j-btn
        v-if="accountsFull.length > 3"
        variant="secondary"
        pill
        class="show-more-btn"
        @click="showMore = !showMore"
      >
        <span v-if="showMore">Show less</span>
        <span v-else>Show more</span>

        <template #append>
          <i-app-accordion-arrow-up v-if="showMore" />
          <i-app-accordion-arrow-down v-else />
        </template>
      </j-btn>
    </div>

    <div v-else class="redelegate-desc">
      <span v-if="!connected">{{ $t('smartStaking.redelegation.notConnected') }}</span>
      <span v-else>{{ $t('smartStaking.redelegation.noAccounts') }}</span>
    </div>
  </section>
</template>

<style lang="scss">
section.redelegate {
  @media (max-width: $breakpoint-xs) {
    .container-title {
      font-size: 24px;
    }
  }
  .redelegate-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-12;

    &__item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: $spacing-40;
      align-items: center;
      gap: $spacing-32;
      border-radius: $spacing-20;
      background: #fff;

      @media (max-width: $breakpoint-xs) {
        padding: $spacing-24;
        gap: $spacing-16;
        flex-wrap: wrap;
      }

      .j-avatar {
        min-width: 64px;
        min-height: 64px;
      }

      .validator-info {
        color: $dark;
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-style: normal;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        flex: 1;
        width: 100px;

        &__name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &__addr {
          color: $neutral-300;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }

        &__amount {
          padding-top: $spacing-8;
        }
      }

      .btn {
        margin-left: auto;

        @media (max-width: $breakpoint-xs) {
          width: 100%;
          margin-top: $spacing-8;
        }
      }
    }
  }

  .show-more-btn {
    margin-top: $spacing-20;

    svg {
      color: transparent;
      width: 16px;
      height: 16px;
    }
  }

  .redelegate-desc {
    text-align: center;
  }

  .j-skeleton {
    border-radius: $spacing-20;
  }
}

body.body--dark {
  section.redelegate {
    .redelegate-list {
      &__item {
        background: $dark-bg;

        .validator-info {
          color: #fff;
        }

        .validator-info__addr {
          color: $neutral-400;
        }
      }
    }
  }
}
</style>
